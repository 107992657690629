import moment from "moment";
import BusinessrApi from "../../api-services/business";
import * as ACTION_TYPE from "./constants";

export const loadDeliveryAreas = ({
  lat,
  lng,
  servingOptionId,
  deliveryAddress,
}) => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPE.LOAD_DELIVERY_OPTIONS.STARTED,
    payload: { lat, lng },
  });

  const businessApi = new BusinessrApi();
  businessApi
    .getDeliveryOptions({
      geoPoint: { longitude: lng, latitude: lat },
      servingOptionId,
      deliveryAddress,
    })
    .then((res) => {
      dispatch({
        type: ACTION_TYPE.LOAD_DELIVERY_OPTIONS.SUCCESS,
        payload: { deliveryOptions: res.deliveryOptions },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: ACTION_TYPE.LOAD_DELIVERY_OPTIONS.FAILED,
        payload: { error },
      });
    });
};

export const loadBranchAvailabiltyIfNeeded = () => (dispatch, getState) => {
  const {
    locations: {
      lastBranchesAvailabiltyFetchTime,
      loadBranchesAvailabilityError,
    },
  } = getState();

  if (
    moment()
      .subtract(1, "minutes")
      .isAfter(lastBranchesAvailabiltyFetchTime) ||
    lastBranchesAvailabiltyFetchTime === null ||
    loadBranchesAvailabilityError
  ) {
    dispatch({ type: ACTION_TYPE.LOAD_BRANCH_AVAILABILTY.STARTED });

    const businessApi = new BusinessrApi();
    businessApi
      .getBranchesAvailability()
      .then((res) => {
        console.log("loaded");
        dispatch({
          type: ACTION_TYPE.LOAD_BRANCH_AVAILABILTY.SUCCESS,
          payload: res,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: ACTION_TYPE.LOAD_BRANCH_AVAILABILTY.FAILED,
          payload: { error },
        });
      });
  }
};

export const loadRegistrationGiftsFromBranch = (branchId) => (
  dispatch,
  getState,
) => {
  dispatch({ type: ACTION_TYPE.LOAD_REGISTRATION_GIFTS_FROM_BRANCH.STARTED });
  const businessApi = new BusinessrApi();
  businessApi
    .getRegistrationGiftsForBranch(branchId)
    .then((registrationGifts) => {
      dispatch({
        type: ACTION_TYPE.LOAD_REGISTRATION_GIFTS_FROM_BRANCH.SUCCESS,
        payload: { registrationGifts },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: ACTION_TYPE.LOAD_REGISTRATION_GIFTS_FROM_BRANCH.FAILED,
        payload: { error },
      });
    });
};
